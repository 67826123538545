import React from "react"
import EyewearCollections from "../components/eyewear_collections"
import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Eyewear" />
    <section className="page-header">
      <h1>Eyewear</h1>
    </section>
    <section className="container my-20">
      <EyewearCollections />
    </section>
</Layout>
)

export default IndexPage
